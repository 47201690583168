import ElasticSearchAPIConnector from '@elastic/search-ui-elasticsearch-connector';
import HttpApiService from './http-api';



const index_name = 'media_library';
const connector = new ElasticSearchAPIConnector({
    host:
        process.env.REACT_ELASTICSEARCH_HOST ||
        'https://search.justbaat.org',
    index: process.env.REACT_ELASTICSEARCH_INDEX || index_name,
    connectionOptions: {
        headers : {"Authorization" : "Basic ZWxhc3RpYzpOVWxTbkhWUGxIUS03eFl2NUZWeQ=="}
    }
});

export const updateDoc = async (id, docData) => {
    try {
        const data = {
            indexName : index_name,
            id,
            data: docData
        }
        const res = await HttpApiService.post('cloud_function',
            `/sdk_api/v1/asset-lib/update`,
            'widget_shown', data);

        console.log('ELASTIC_SEARCH ADD_DOC SUCCESS', index_name, docData, res);
    } catch (error) {
        console.error('ELASTIC_SEARCH ADD_DOC ERROR', index_name, docData, error);
    }
}

export const deleteDoc = async (id) => {
    try {
        const data = {
            indexName : index_name,
            id
        }
        const res = await HttpApiService.post('cloud_function',
            `/sdk_api/v1/asset-lib/delete`,
            'widget_shown', data);

        console.log('ELASTIC_SEARCH DELETE_DOC SUCCESS', index_name, id, res);
    } catch (error) {
        console.error('ELASTIC_SEARCH DELETE_DOC ERROR', index_name, id, error);
    }
}

export const DEFAULT_PAGE_CONFIG = {
    debug: true,
    alwaysSearchOnInitialLoad: true,
    apiConnector: connector,
    hasA11yNotifications: true,
    onSearch: (requestState, queryConfig, next) => {
        let updatedState = requestState;
        if (requestState.searchTerm === "" && requestState.sortList.length === 0) {
            updatedState = {
                ...requestState,
                sortList: [{ field: "created_at", direction: "desc" }]
            };
        }
        return Promise.resolve(next(updatedState, queryConfig));
    },
    searchQuery: {
        filters: [],
        search_fields: {
            title: { weight : 3 },
            description: {},
            assetType: {},
        },
        result_fields: {
            assets: { raw: {} },
            source: { raw: {} },
            category: { raw: {} },
            tags: { raw: {} },
            title: {
                snippet: {
                    size: 100,
                    fallback: true
                }
            },
            assetType: { raw: {} },
            created_at: { raw: {} },
            description: {
                snippet: {
                    size: 100,
                    fallback: true
                }
            }
        },
        disjunctiveFacets: [
            'source',
            'assetType.keyword',
            'category',
        ],
        facets: {
            'source.keyword': { type: 'value' },
            'category.keyword': { type: 'value' },
            'assetType.keyword': { type: 'value'},
        }
    },
    autocompleteQuery: {
        results: {
            search_fields: {
                parks_search_as_you_type: {}
            },
            resultsPerPage: 5,
            result_fields: {
                title: {
                    snippet: {
                        size: 100,
                        fallback: true
                    }
                },
                description: {
                    raw: {}
                }
            }
        },
        suggestions: {
            types: {
                documents: {
                    fields: ['title']
                }
            },
            size: 4
        }
    }
};

export const SORT_OPTIONS = [
    {
        name: 'Relevance',
        value: []
    },
    {
        name: 'Date',
        value: [
            {
                field: 'created_at',
                direction: 'desc'
            }
        ]
    },
    {
        name: 'Asset Type',
        value: [
            {
                field: 'assetType.keyword',
                direction: 'asc'
            }
        ]
    },
    {
        name: 'Asset Type -> Title',
        value: [
            {
                field: 'assetType.keyword',
                direction: 'asc'
            },
            {
                field: 'title.keyword',
                direction: 'asc'
            }
        ]
    },
    {
        name: 'Source -> Type -> Title',
        value: [
            {
                field: 'source.keyword',
                direction: 'asc'
            },
            {
                field: 'assetType.keyword',
                direction: 'asc'
            },
            {
                field: 'title.keyword',
                direction: 'asc'
            }
        ]
    }
];
