import React from 'react';
import {
    ErrorBoundary,
    Facet,
    Paging,
    PagingInfo,
    Results,
    ResultsPerPage,
    SearchBox,
    SearchProvider,
    Sorting,
    WithSearch
} from '@elastic/react-search-ui';
import { BooleanFacet, Layout, SingleSelectFacet } from '@elastic/react-search-ui-views';
import '@elastic/react-search-ui-views/lib/styles/styles.css';
import { DEFAULT_PAGE_CONFIG, SORT_OPTIONS } from './Config';
import { CustomResultsView, CustomResultView } from './CustomComponents';



export default function App() {
    return (
        <SearchProvider config={DEFAULT_PAGE_CONFIG}>
            <WithSearch
                mapContextToProps={({ wasSearched }) => ({
                    wasSearched
                })}
            >
                {({ wasSearched }) => {
                    return (
                        <div className="App">
                            <ErrorBoundary>
                                <Layout
                                    header={
                                        <SearchBox
                                            autocompleteMinimumCharacters={3}
                                            autocompleteResults={{
                                                linkTarget: '_blank',
                                                sectionTitle: 'Results',
                                                titleField: 'title',
                                                urlField: 'assets',
                                                shouldTrackClickThrough: true,
                                                clickThroughTags: ['test']
                                            }}
                                            autocompleteSuggestions={true}
                                            debounceLength={0}
                                        />
                                    }
                                    sideContent={
                                        <div>
                                            {wasSearched && (
                                                <Sorting label={'Sort by'} sortOptions={SORT_OPTIONS}/>
                                            )}
                                            <Facet
                                                field="source.keyword"
                                                label="Source"
                                                filterType="any"
                                                isFilterable={true}
                                            />
                                            <Facet
                                                field="assetType.keyword"
                                                label="Asset Type"
                                                view={BooleanFacet}
                                            />
                                            <Facet
                                                field="category.keyword"
                                                label="Category"
                                                view={SingleSelectFacet}
                                            />
                                        </div>
                                    }
                                    bodyContent={
                                        <Results
                                            view={CustomResultsView} resultView={CustomResultView}
                                            titleField="title"
                                            urlField="assets"
                                            thumbnailField="assets"
                                            shouldTrackClickThrough={true}
                                        />
                                    }
                                    bodyHeader={
                                        <React.Fragment>
                                            {wasSearched && <PagingInfo/>}
                                            {wasSearched && <ResultsPerPage/>}
                                        </React.Fragment>
                                    }
                                    bodyFooter={<Paging/>}
                                />
                            </ErrorBoundary>
                        </div>
                    );
                }}
            </WithSearch>
        </SearchProvider>
    );
}
