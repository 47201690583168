import EdiText from 'react-editext';
import { deleteDoc, updateDoc } from './Config';
// import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Card, Image, ListGroup } from 'react-bootstrap';
import moment from 'moment';
import { BsClock } from "react-icons/bs";

export const CustomResultsView = ({ children }) => {
    return (

        <div className="relative overflow-x-auto">
            <section style={{}}>{children}</section>
        </div>
    );
};

export const CustomResultView = ({ result }) => {

    // const [tags, setTags] = useState(result.tags !== undefined ? result.tags.raw : []);
    const [disable, setDisable] = useState(true);
    const [removeComponent, setRemoveComponent] = useState('flex');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onSave = (val, out) => {
        result[`${out}`].raw = val;
        console.log('Edited Value -> ', val, result.title.raw);
        setDisable(false);
    };

    const onClick = async () => {
        const docData = {
            title: result.title.raw,
            category: result.category.raw,
            // tags: tags
        };
        await updateDoc(result.id.raw, docData);
    };

    const onDelete = async () => {
        await deleteDoc(result.id.raw);
        setRemoveComponent('none');
    };

    // const handleTagChange = (val) => {
    //     setTags(val);
    //     setDisable(false);
    // };

    return (
        <>
            <Card
                style={{
                    float: 'left',
                    height: '30vw',
                    margin: '1%',
                    width: '31.3%',
                    display : `${removeComponent}`
                }}
            >

                <Card.Img
                    variant={'top'}
                    src={result.assets.raw}
                    alt={result.title.raw}
                    style={{ height: '15vw' }}
                    onClick={handleShow}
                />
                <Card.Body style={{ padding: '5px', marginTop: 10, fontSize: '14px' }}>
                    <Card.Subtitle>{result.title.raw}</Card.Subtitle>

                    <Card.Text style={{ marginTop: 10 }}>
                        <ListGroup.Item><b>Source</b>: {result.source.raw}</ListGroup.Item>
                        <ListGroup.Item><b>Category</b>: {result.category.raw}</ListGroup.Item>
                        {/*<ListGroup.Item><b>Tags</b>: {tags}</ListGroup.Item>*/}
                    </Card.Text>

                    <label style={{ fontSize: '12px' }}><BsClock/> {result.created_at ? `${moment(new Date(result.created_at.raw)).format("Do MMMM  h:mm a")}` : ``}</label>
                </Card.Body>
                <Card.Footer className={'d-flex justify-content-between'}>
                    <Button variant="primary" size="sm" onClick={handleShow}>Edit</Button>
                    <Button variant="danger" size="sm" onClick={onDelete}>Delete</Button>
                </Card.Footer>

            </Card>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Asset</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <a href={result.assets.raw} target={'_blank'} rel="noreferrer">
                        <Image
                            src={result.assets.raw}
                            alt={result.title.raw}
                            style={{ width: '100%', height: '50%' }}
                        />
                    </a>

                    <Card.Body style={{ padding: '5px', fontSize: '12px' }}>
                        <div style={{ display: 'flex', margin: '10px 0' }}><b>Title:</b>
                            <EdiText
                                type="text"
                                value={result.title.raw}
                                onSave={(val) => onSave(val, 'title')}
                            />
                        </div>
                        <div style={{ display: 'flex', margin: '10px 0' }}>
                            <b>Source:</b>
                            {result.source.raw}
                        </div>
                        <div style={{ display: 'flex', margin: '10px 0' }}>
                            <b>Category:</b>
                            <EdiText
                                type="text"
                                value={result.category? result.category.raw : ''}
                                onSave={(val) => onSave(val, 'category')}
                            />
                        </div>
                        <div style={{ display: 'flex', margin: '10px 0' }}>
                            <b>Person:</b>
                            <EdiText
                                type="text"
                                value={result.person ? result.person.raw : ''}
                                onSave={(val) => onSave(val, 'person')}
                            />
                        </div>
                        <div style={{ display: 'flex', margin: '10px 0' }}>
                            <b>Object:</b>
                            <EdiText
                                type="text"
                                value={result.object ? result.object.raw : ''}
                                onSave={(val) => onSave(val, 'object')}
                            />
                        </div>
                        <div style={{ display: 'flex', margin: '10px 0' }}>
                            <b>Place:</b>
                            <EdiText
                                type="text"
                                value={result.place? result.place.raw : ''}
                                onSave={(val) => onSave(val, 'place')}
                            />
                        </div>
                        <div style={{ display: 'flex', margin: '10px 0' }}>
                            <b>News:</b>
                            <EdiText
                                type="text"
                                value={result.news ? result.news.raw : ''}
                                onSave={(val) => onSave(val, 'news')}
                            />
                        </div>
                        <div style={{ display: 'flex', margin: '10px 0' }}>
                            <b>Entity:</b>
                            <EdiText
                                type="text"
                                value={result.entity ? result.entity.raw : ''}
                                onSave={(val) => onSave(val, 'entity')}
                            />
                        </div>
                    </Card.Body>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={onClick} disabled={disable}>Save Changes</Button>
                </Modal.Footer>
            </Modal></>
    );
};
