const cloudFunctionBaseUrl = "https://asia-south1-justbaat-dev.cloudfunctions.net";

const HttpApiService = {
    /**
     *
     * @param {'cloud_function' | 'other'} requestType 'cloud_function' | 'other'
     * @param {string} endpoint Complete url if not cloud_function call, else only end    point
     * @param errorCode
     * @param {{action: string, id}} timeout Request timeout, default is 10000
     * @returns {Promise} Promise Object
     */
    get: async (requestType: string, endpoint: string, errorCode: string, timeout = 10000) => {
        try {
            if (!requestType && !endpoint && !errorCode) {
                return Promise.reject({
                    code: 123,
                    message: 'Check parameters'
                });
            }

            let url;
            let config: any = {};

            if (requestType === 'cloud_function') {
                url = createCloudFunctionUrl(endpoint);

                config = await createCloudFunctionConfig(timeout);
            } else {
                url = endpoint;
            }

            const resp = await fetch(url, {
                method: 'GET', // or 'PUT'
                headers: config.headers
            });

            const response = await resp.json();

            if (!resp.ok) {
                throw response;
            }

            return Promise.resolve(response);
        } catch (error) {
            console.log('121 HttpApiService Response Error123 => ', error);

            return Promise.reject(error);
        }
    },

    /**
     *
     * @param {'cloud_function' | 'other'} requestType 'cloud_function' | 'other'
     * @param {string} endpoint Complete url if not cloud_function call, else only end point
     * @param {string} errorCode Error code for logging
     * @param {any} data Data to send with POST request
     * @param {number} timeout Request timeout, default is 10000
     * @param {boolean} fileUpload File to upload
     * @returns {Promise} Promise Object
     */
    post: async (
        requestType: string,
        endpoint: string,
        errorCode: string,
        data: object,
        timeout: number = 10000,
        fileUpload: boolean = false
    ): Promise<any> => {
        try {
            if (!requestType && !endpoint && !errorCode && !data) {
                return Promise.reject({
                    code: 123,
                    message: 'Check parameters'
                });
            }

            let url;
            let config: any = {};

            if (requestType === 'cloud_function') {
                url = createCloudFunctionUrl(endpoint);

                config = await createCloudFunctionConfig(timeout, fileUpload);
            } else {
                url = endpoint;
            }

            const resp = await fetch(url, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify(data)
            });

            const response = await resp.json();

            if (!resp.ok) {
                throw response;
            }

            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }
};

function createCloudFunctionUrl(endpoint: string) {
    // let baseUrl = cloudFunctionBaseUrl;
    // if (isDebug) baseUrl = 'http://localhost:5001/justbaat-debug/asia-south1';

    return `${cloudFunctionBaseUrl}${endpoint}`;
}

async function createCloudFunctionConfig(timeout: number, fileUpload = false) {
    // const token = await getAuth().currentUser.getIdToken();

    // console.log({ token });

    const config = {
        headers: {
            // Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        timeout
    };

    if (fileUpload) {
        config.headers['Content-Type'] = 'multipart/form-data';
    }

    return config;
}

export default HttpApiService;
